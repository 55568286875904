import classNames from "classnames";
import { t } from "i18n-js";
import { useThemeContext } from "@/react/providers";
import type { Space } from "@circle-react/types";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export interface SpaceSelectButton {
  selectedSpace: Space;
  shouldShowEmoji: boolean;
  value: number;
  defaultLabel?: string;
  shouldDisplayHomepage: boolean;
  toggleDropdown: () => void;
}

export const SpaceSelectButton = ({
  selectedSpace,
  toggleDropdown,
  shouldShowEmoji,
  value,
  shouldDisplayHomepage,
  defaultLabel = t("search_space"),
}: SpaceSelectButton) => {
  const isHomeSpace = shouldDisplayHomepage && value === 0;
  const label = selectedSpace?.name ||
    (isHomeSpace && t("home_page.title")) || (
      <Typography.LabelMd color="text-light">{defaultLabel}</Typography.LabelMd>
    );
  const { currentAppearance } = useThemeContext();

  return (
    <button
      type="button"
      className="react-space-selector__space-btn"
      onClick={toggleDropdown}
    >
      <div
        className={classNames("react-space-selector__space-name", {
          "flex gap-2.5": shouldShowEmoji,
        })}
      >
        {shouldShowEmoji && selectedSpace && (
          <EmojiRenderer
            className="flex !h-5 !w-5 items-center justify-center"
            emoji={selectedSpace.emoji}
            appearance={currentAppearance}
            customEmojiUrl={selectedSpace.custom_emoji_url}
            customEmojiDarkUrl={selectedSpace.custom_emoji_dark_url}
          />
        )}
        {label}
      </div>{" "}
      <div className="text-dark flex">
        <Icon type="20-chevron-down-sm" size={20} />
      </div>
    </button>
  );
};
