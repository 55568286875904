import PropTypes from "prop-types";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { Icon } from "@circle-react-shared/Icon";
import { Tippy } from "@circle-react-shared/Tippy";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const { ItemWithLink } = Dropdown;

export const SCREEN_SIZES = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
};

export const ScreenSizeSelect = ({
  selected = SCREEN_SIZES.DESKTOP,
  onSelect,
}) => {
  const options = [
    {
      label: t("desktop"),
      value: SCREEN_SIZES.DESKTOP,
      icon: "16-desktop",
      selectedIcon: "20-desktop",
    },
    {
      label: t("tablet"),
      value: SCREEN_SIZES.TABLET,
      icon: "16-tablet",
      selectedIcon: "20-tablet",
    },
    {
      label: t("mobile"),
      value: SCREEN_SIZES.MOBILE,
      icon: "16-mobile",
      selectedIcon: "20-mobile",
    },
  ];

  const selectedOption =
    options.find(option => option.value === selected) || options[0];

  const { label, selectedIcon } = selectedOption;

  return (
    <Dropdown
      buttonWrapperClassName="flex items-center"
      button={
        <Tippy
          content={t("change_preview")}
          boundary="viewport"
          popperOptions={{
            placement: "bottom",
          }}
        >
          <div className="text-dark flex h-7 w-7 items-center p-1">
            <Icon size={20} type={selectedIcon} />
            <span className="sr-only">{label}</span>
          </div>
        </Tippy>
      }
    >
      {options.map(option => (
        <ItemWithLink
          key={option.value}
          value={option.value}
          onClick={() => {
            isFunction(onSelect) && onSelect(option.value);
          }}
          linkType="button"
        >
          <div className="text-dark flex items-center justify-between">
            <span>
              <Icon size={16} type={option.icon} className="mr-[10px] !w-4" />
              {option.label}
            </span>
            {option.value === selected && (
              <Icon size={12} type="12-check-circle" className="!w-3" />
            )}
          </div>
        </ItemWithLink>
      ))}
    </Dropdown>
  );
};

ScreenSizeSelect.propTypes = {
  selected: PropTypes.oneOf([
    SCREEN_SIZES.DESKTOP,
    SCREEN_SIZES.TABLET,
    SCREEN_SIZES.MOBILE,
  ]),
  onSelect: PropTypes.func,
};
