import classNames from "classnames";
import { useThemeContext } from "@/react/providers";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";

export interface MemberTagResultProps {
  record: Record;
  onClick?: (record: Record) => void;
  isSelected?: boolean;
}

export const MemberTagResult = ({
  record,
  onClick,
  isSelected,
}: MemberTagResultProps) => {
  const { currentAppearance } = useThemeContext();
  const ref = useScrollIntoView(isSelected);
  const {
    name,
    emoji,
    custom_emoji_url,
    custom_emoji_dark_url,
    tagged_members_count: count,
  } = record;
  const hasEmoji = emoji || custom_emoji_url || custom_emoji_dark_url;

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className={classNames(
        "hover:bg-tertiary flex w-full cursor-pointer items-center gap-3 px-5 py-2 text-left first:pt-4 last:pb-4",
        { "bg-tertiary": isSelected },
      )}
      onClick={handleClick}
      ref={ref}
    >
      <span>
        <EmojiRenderer
          className="!h-5 !w-auto"
          emoji={emoji}
          customEmojiUrl={custom_emoji_url}
          customEmojiDarkUrl={custom_emoji_dark_url}
          appearance={currentAppearance}
        />
      </span>
      {!hasEmoji && <Icon size={16} type="hash-v2" />}
      <span className="grow">
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </span>
      <span>
        <MemberCount count={count} />
      </span>
    </button>
  );
};
