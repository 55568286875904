import { uniq } from "lodash";
import { RECORD_TYPES } from "../helpers";
import { SELECTION_ACTIONS } from "./selectionActions";

export const initialState = (value, includeAllResultTypes = false) => ({
  spaceGroups: [],
  spaces: [],
  memberTags: [],
  communityMembers: [],
  memberCount: 0,
  isLoading: false,
  errorMessage: null,
  value: !includeAllResultTypes
    ? [...(value || [])]
    : {
        members_ids: [],
        spaces_ids: [],
        space_groups_ids: [],
        member_tags_ids: [],
        ...(value || {}),
      },
});

// eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
export const selectionReducer = (state, action) => {
  const { type, payload } = action;
  const { record, includeAllResultTypes, shouldUpdateValue } = payload || {};
  const { type: recordType } = record || {};

  switch (type) {
    case SELECTION_ACTIONS.ADD: {
      // eslint-disable-next-line sonarjs/no-nested-switch  -- Disabled to set CI to fail on this issue on new files, PR #6718
      switch (recordType) {
        case RECORD_TYPES.SPACE_GROUP: {
          return {
            ...state,
            spaceGroups: [...state.spaceGroups, record],
            memberCount:
              state.memberCount +
              (record.active_space_group_members_count || 0),
            value: shouldUpdateValue
              ? {
                  ...state.value,
                  space_groups_ids: uniq([
                    ...state.value.space_groups_ids,
                    record.id,
                  ]),
                }
              : state.value,
          };
        }
        case RECORD_TYPES.SPACE: {
          return {
            ...state,
            spaces: [...state.spaces, record],
            memberCount:
              state.memberCount + (record.active_space_members_count || 0),
            value: shouldUpdateValue
              ? {
                  ...state.value,
                  spaces_ids: uniq([...state.value.spaces_ids, record.id]),
                }
              : state.value,
          };
        }
        case RECORD_TYPES.MEMBER_TAG: {
          return {
            ...state,
            memberTags: [...state.memberTags, record],
            memberCount: state.memberCount + (record.tagged_members_count || 0),
            value: shouldUpdateValue
              ? {
                  ...state.value,
                  member_tags_ids: uniq([
                    ...state.value.member_tags_ids,
                    record.id,
                  ]),
                }
              : state.value,
          };
        }
        case RECORD_TYPES.COMMUNITY_MEMBER:
        default: {
          return {
            ...state,
            communityMembers: [...state.communityMembers, record],
            memberCount: state.memberCount + 1,
            value: !shouldUpdateValue
              ? state.value
              : !includeAllResultTypes
              ? [...state.value, record.id]
              : {
                  ...state.value,
                  members_ids: uniq([...state.value.members_ids, record.id]),
                },
          };
        }
      }
    }
    case SELECTION_ACTIONS.REMOVE: {
      // eslint-disable-next-line sonarjs/no-nested-switch -- Disabled to set CI to fail on this issue on new files, PR #6718
      switch (recordType) {
        case RECORD_TYPES.SPACE_GROUP: {
          return {
            ...state,
            spaceGroups: state.spaceGroups.filter(
              spaceGroup => spaceGroup.id !== record.id,
            ),
            memberCount:
              state.memberCount -
              (record.active_space_group_members_count || 0),
            value: {
              ...state.value,
              space_groups_ids: uniq(
                state.value.space_groups_ids.filter(
                  spaceGroupId => spaceGroupId !== record.id,
                ),
              ),
            },
          };
        }
        case RECORD_TYPES.SPACE: {
          return {
            ...state,
            spaces: state.spaces.filter(space => space.id !== record.id),
            memberCount:
              state.memberCount - (record.active_space_members_count || 0),
            value: {
              ...state.value,
              spaces_ids: uniq(
                state.value.spaces_ids.filter(spaceId => spaceId !== record.id),
              ),
            },
          };
        }
        case RECORD_TYPES.MEMBER_TAG: {
          return {
            ...state,
            memberTags: state.memberTags.filter(
              memberTag => memberTag.id !== record.id,
            ),
            memberCount: state.memberCount - (record.tagged_members_count || 0),
            value: {
              ...state.value,
              member_tags_ids: uniq(
                state.value.member_tags_ids.filter(
                  memberTagId => memberTagId !== record.id,
                ),
              ),
            },
          };
        }
        case RECORD_TYPES.COMMUNITY_MEMBER:
        default: {
          return {
            ...state,
            communityMembers: state.communityMembers.filter(
              communityMember => communityMember.id !== record.id,
            ),
            memberCount: state.memberCount - 1,
            value: !includeAllResultTypes
              ? uniq(
                  state.value.filter(
                    communityMemberId => communityMemberId !== record.id,
                  ),
                )
              : {
                  ...state.value,
                  members_ids: uniq(
                    state.value.members_ids.filter(
                      memberId => memberId !== record.id,
                    ),
                  ),
                },
          };
        }
      }
    }
    case SELECTION_ACTIONS.INITIAL_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SELECTION_ACTIONS.INITIAL_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SELECTION_ACTIONS.INITIAL_FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.errorMessage,
      };
    }
    case SELECTION_ACTIONS.RESET: {
      return initialState(null, !!payload.includeAllResultTypes);
    }
    default:
      return state;
  }
};
