import type { ReactNode } from "react";
import { isFunction } from "lodash";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface ActionModalProps {
  actionText?: string;
  actionVariant?: "primary" | "danger" | "circle";
  children: ReactNode;
  contentPortalId?: string;
  disabled?: boolean;
  formId?: string;
  isOpen?: boolean;
  onAction?: () => void;
  onClose?: () => void;
  size?: "sm" | "md";
  title: string;
  modalContentClassName?: string;
}

export const ActionModal = ({
  actionText,
  actionVariant = "primary",
  children,
  contentPortalId = "",
  disabled = false,
  formId,
  isOpen = false,
  onAction,
  onClose,
  size = "md",
  title,
  modalContentClassName = "",
}: ActionModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={() => isFunction(onClose) && onClose()}
    contentPortalId={contentPortalId}
  >
    <Modal.Overlay />
    <Modal.Content size={size} className={modalContentClassName}>
      <Modal.Header>
        <Modal.Title size={size} className="text-center">
          {title}
        </Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          {isFunction(onClose) && (
            <Modal.CloseButton
              onClick={() => isFunction(onClose) && onClose()}
            />
          )}
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {actionText && (
        <Modal.Footer>
          <Button
            type={formId ? "submit" : "button"}
            form={formId}
            full
            large
            variant={actionVariant}
            disabled={disabled}
            onClick={() => isFunction(onAction) && onAction()}
          >
            {actionText}
          </Button>
        </Modal.Footer>
      )}
    </Modal.Content>
  </Modal>
);
