import { useEffect, useState } from "react";
import type { IconType } from "@circle-react-shared/Icon";
import { RadioButton } from "./RadioButton";

export interface Option {
  value: string;
  label: string;
  icon?: IconType;
  iconSize?: number;
}

export interface RadioButtonGroupProps {
  name: string;
  options: Option[];
  defaultChecked: string;
  onChange?: (value: any) => void;
  size?: "sm" | "md";
  hasRadioCheckmarks?: boolean;
}

export const RadioButtonGroup = ({
  name,
  options,
  defaultChecked,
  onChange,
  size = "md",
  hasRadioCheckmarks = false,
}: RadioButtonGroupProps) => {
  const [checkedOption, setCheckedOption] = useState(defaultChecked);

  useEffect(() => {
    if (checkedOption && onChange) {
      onChange(checkedOption);
    }
  }, [onChange, checkedOption]);

  return (
    <div className="flex flex-col gap-3">
      {options.map(({ value, label, icon, iconSize }, index) => (
        <RadioButton
          data-testid={`radio-button-group-${index}`}
          isChecked={value === checkedOption}
          onChange={setCheckedOption}
          key={value}
          label={label}
          name={name}
          id={label}
          value={value}
          size={size}
          hasRadioCheckmarks={hasRadioCheckmarks}
          icon={icon}
          iconSize={iconSize}
        />
      ))}
    </div>
  );
};
