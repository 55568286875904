import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useBoolean } from "react-use";
import invariant from "tiny-invariant";

interface ButtonDropdownContextType {
  isDropdownOpen: boolean;
  toggleDropdownOpen: () => void;
}

const ButtonDropdownContext = createContext<ButtonDropdownContextType | null>(
  null,
);
ButtonDropdownContext.displayName = "ButtonDropdownContext";

export const useButtonDropdownContext = () => {
  const context = useContext(ButtonDropdownContext);
  invariant(
    context,
    "useButtonDropdownContext must be used within a ButtonDropdownProvider",
  );
  return context;
};

interface ButtonDropdownContextProviderProps {
  children: ReactNode;
}

/**
 * Buttons inside the editor can trigger the dropdown to edit contents.
 * This context provider is used to manage the state of the dropdown.
 */
export function ButtonDropdownContextProvider({
  children,
}: ButtonDropdownContextProviderProps) {
  const [isDropdownOpen, toggleDropdownOpen] = useBoolean(false);
  const ctxValue = useMemo(
    () => ({ isDropdownOpen, toggleDropdownOpen }),
    [isDropdownOpen, toggleDropdownOpen],
  );

  return (
    <ButtonDropdownContext.Provider value={ctxValue}>
      {children}
    </ButtonDropdownContext.Provider>
  );
}
