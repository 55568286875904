import classNames from "classnames";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";

export interface SpaceGroupResultProps {
  record: Record;
  onClick?: (record: Record) => void;
  isSelected: boolean;
}

export const SpaceGroupResult = ({
  record,
  onClick,
  isSelected,
}: SpaceGroupResultProps) => {
  const ref = useScrollIntoView(isSelected);
  const { name, active_space_group_members_count: count } = record;
  const handleClick = () => {
    onClick && onClick(record);
  };
  return (
    <button
      type="button"
      className={classNames(
        "hover:bg-tertiary flex w-full cursor-pointer items-center gap-3 px-5 py-2 text-left first:pt-4 last:pb-4",
        { "bg-tertiary": isSelected },
      )}
      onClick={handleClick}
      ref={ref}
    >
      <Icon size={16} type="bookmark-folder" />
      <div className="grow">
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </div>
      <MemberCount count={count} />
    </button>
  );
};
